import _Vue, { VueConstructor } from 'vue';
import LaunchDarklyClient from '@zomaroninc/libs-frontend/dist/src/launchDarklyClient/';

class VueLaunchDarklyClient extends LaunchDarklyClient {
  private vm: _Vue;

  constructor(params: any) {
    super(params);
    this.vm = this.initVm();
  }

  private initVm() {
    return new _Vue({
      data: this,
    });
  }
}

const featureFlagPlugin = (Vue: VueConstructor<_Vue>, params: any): void => {
  const featureFlags = new VueLaunchDarklyClient(params);
  Vue.prototype.$featureFlags = featureFlags;
};

export default featureFlagPlugin;
