// tslint:disable:max-line-length
import Locale from './locale';
import { Locale as LocaleEnum } from '@paystone/locale';

const en: Locale = {
  PageTitle: `Paystone Account`,
  ResetPassword: {
    navButtonLabel: `Go to Login`,
    title: `Reset Your Password`,
    body: `Please enter your new password below. You'll then be redirected to the login page.`,
    passwordLabel: `New Password`,
    passwordConfirmLabel: `Confirm New password`,
    passwordConfirmMismatch: `The passwords do not match.`,
    resetButtonLabel: `Reset Password`,
    successTitle: 'Password Changed',
    successBody:
      'Your password has been reset. You can now log in using your new password.',
    successButtonLabel: 'Go to Login',
    Modal: {
      dismiss: `Close`,
      tokenGenericErrorModal: `Your account has already been verified. You’ll now be redirected to the login page.`,
      tokenRequestNotFoundModal: `An error occurred, or the link has expired. Please request a new password again.`,
      tokenSuccessModal: `Your password has been reset. You’ll now be redirected to the login page.`,
      tokenAlreadyAccepted: `Your password has been reset. You’ll now be redirected to the login page.`,
      tokenExpiredModal: `That link has expired. You’ll now be redirected to the login page, where you can request a new password if needed.`,
    },
  },
  EmailChanged: {
    verifyingText: 'Verifying...',
    title: 'Email Address Changed',
    body: 'You can now log into your account.',
    buttonLabel: 'Go to Login',
    Modal: {
      dismiss: `Close`,
      tokenGenericErrorModal: `Something went wrong. You will now be directed back to the login page.`,
      tokenAlreadyAccepted: `Your email address has already been verified. You’ll now be redirected to the login page, where you can sign in using your new email address.`,
      tokenRequestNotFoundModal: `An error occurred. Please try changing your email address again.`,
      tokenExpiredModal: `That link has expired. You’ll now be redirected to the login page, where you can sign in with your old email address and change it if needed.`,
      tokenSuccessModal: `Your new email address has been verified. You’ll now be redirected to the login page, where you can sign in with your new email address.`,
    },
  },
  SetupAccount: {
    title: `Create an Account.`,
    body: `Please fill out this form to create a {brandName} account.`,
    addressHeader: `Address`,
    profileHeader: `Profile`,
    emailLabel: `Email Address`,
    emailInvalid: `Please enter a valid email address.`,
    fieldRequired: `This field is required.`,
    firstNameLabel: `First name`,
    lastNameLabel: `Last name`,
    passwordConfirmLabel: `Confirm password`,
    passwordConfirmMismatch: `Passwords do not match.`,
    passwordLabel: `Password`,
    setupButtonLabel: `Create Account`,
    tosAgreementHeader: `Terms of Use and Privacy Policy`,
    tosAgreementLabel: `By checking this box, you agree to the {brandName}`,
    tosLinkLabel: `terms of service.`,
    verifiedTitle: `Your account has been created.`,
    verifiedTagline: `Thanks for creating a {brandName} account. You can now log in to your account.`,
    verifiedButtonLabel: `Go to Login`,
    unverifiedTitle: `Verification Required`,
    unverifiedTagline: `Thank you for creating a {brandName} account. We've sent you a confirmation email. Please verify your account by clicking the link in the email.`,
    unverifiedButtonLabel: `Go to Login`,
    Modal: {
      dismiss: `Close`,
      tokenGenericErrorModal: `Something went wrong. You will now be directed back to the login page.`,
      tokenAlreadyAccepted: `An account has already been created using that link. You’ll now be redirected to the login page.`,
      tokenRequestNotFoundModal: `An error occurred. You’ll now be redirected to the login page.`,
      tokenExpiredModal: `That link has expired. A new account setup link has been sent to your email address. Please check your inbox.`,
      tokenSuccessModal: `Your account has been created. You’ll now be redirected to the login page to sign in.`,
      emailAlreadyInUse: `That email address is already in use.`,
    },
    preferredLanguage: 'Preferred Language for Communication',
    english: 'English',
    french: 'French',
  },
  VerifyUser: {
    verifyingText: 'Verifying...',
    title: 'Success!',
    body: 'Your account has been verified. You may now log in to your account.',
    buttonLabel: 'Go to Login',
    Modal: {
      dismiss: `Close`,
      tokenGenericErrorModal: `Something went wrong. You will now be directed back to the login page.`,
      tokenAlreadyAccepted: `Your account has already been verified. You’ll now be redirected to the login page.`,
      tokenRequestNotFoundModal: `An error occurred. Please check that your email address is properly spelled or try creating a new account.`,
      tokenExpiredModal: `That link has expired. You’ll now be redirected to the login page.`,
      tokenSuccessModal: `Your account has been verified. You’ll now be redirected to the login page, where you can log into your account.`,
      tokenReissueExpiredModal: `That link has expired. A new account setup link has been sent to your email address. Please check your inbox.`,
    },
  },
  ForgotPassword: {
    title: `Forgot Your Password?`,
    body: `Enter your email below, and we'll send you password reset instructions.`,
    buttonLabel: `Send`,
    emailInvalid: `Please enter a valid email address.`,
    emailLabel: `Email Address`,
    successTitle: 'Please check your inbox.',
    successBody:
      `We've emailed you instructions to reset your password.`,
    successButtonLabel: 'Go to Login',
    Modal: {
      accountNotVerified: `Your account has been created, but it still needs to be verified. Please check your inbox for a verification email.`,
      dismiss: `Close`,
      emailNotRegistered: `No account was found matching that email address.`,
      emailSent: `Further instructions to reset your password have been sent to you by email. Please check your inbox.`,
      genericError: `Something went wrong. Please try again later.`,
    },
  },
  GivepointUnsubscribe: {
    title: `{brandName} Communications`,
    body: `Please wait, while we process your unsubscription request.`,
    success: `You are now unsubscribed from {brandName} communications.`,
    error: `Failed to unsubscribe, please try again later.`,
  },
  Locales: {
    [LocaleEnum.enCA]: 'English',
    [LocaleEnum.frCA]: 'Français',
  },
};

export default en;
