import { Component, Prop } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import ValidateTokenService, {
  TokenStatus,
} from '@/services/ValidateTokenService';
import BrandMixin from './BrandMixin';

export interface Locale {
  tokenSuccessModal: string;
  tokenRequestNotFoundModal: string;
  tokenGenericErrorModal: string;
  tokenAlreadyAccepted: string;
  tokenExpiredModal: string;
  dismiss: string;
}

@Component
export default class ValidateTokenMixin extends BrandMixin {
  @Prop({ type: String, default: '', required: false })
  protected tokenId?: string;
  protected user?: User;
  protected checkTokenResponse: ValidateTokenServiceResponse | null = null;
  protected loadingToken: boolean = false;

  protected modalMessage: VueI18n.TranslateResult = this.locale(
    'Modal.tokenGenericErrorModal',
  );

  protected locale(value: string) {
    return this.$t(`${value}`);
  }

  protected showModal(modalLocaleKey: string): void {
    this.modalMessage = this.locale(`Modal.${modalLocaleKey}`);
    this.$ZModal.show('platformTokenModal');
  }

  protected async checkToken() {
    if (this.tokenId) {
      try {
        this.loadingToken = true;
        this.checkTokenResponse = await ValidateTokenService.getToken(
          this.tokenId,
        );

        if (this.checkTokenResponse.platform) {
          this.platform = this.checkTokenResponse.platform;
        }

        switch (this.checkTokenResponse.status) {
          case TokenStatus.ALREADY_ACCEPTED: {
            this.showModal('tokenAlreadyAccepted');
            break;
          }
          case TokenStatus.EXPIRED: {
            this.showModal('tokenExpiredModal');
            break;
          }
          case TokenStatus.NOT_FOUND: {
            this.showModal('tokenRequestNotFoundModal');
            break;
          }
          case TokenStatus.ERROR: {
            this.showModal('tokenGenericErrorModal');
            break;
          }
        }
      } catch (error) {
        this.showModal('tokenGenericErrorModal');
      } finally {
        this.loadingToken = false;
      }
    } else {
      this.showModal('tokenGenericErrorModal');
    }
  }
}
