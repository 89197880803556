

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LogoHeader from '@/components/LogoHeader/LogoHeader.vue';
import LandingCard from '@/layouts/LandingCard/LandingCard.vue';
import VerifyingLayout from '@/layouts/VerifyingLayout/VerifyingLayout.vue';
import LandingLayout from '@/layouts/LandingLayout/LandingLayout.vue';
import VerifyTokenMixin from '../../mixins/VerifyTokenMixin';

import { resetPassword } from '@/scripts/resetPassword';
import VueI18n from 'vue-i18n';
import ValidateTokenService, {
  TokenStatus,
  VerifyTokenTypes,
} from '@/services/ValidateTokenService';

@Component({
  components: {
    LandingCard,
    LandingLayout,
    LogoHeader,
    VerifyingLayout,
  },
})
export default class EmailChanged extends VerifyTokenMixin {
  protected locale(value: string) {
    return this.$t(`EmailChanged.${value}`, { brandName: this.brandName });
  }
  protected verifyTokenType: VerifyTokenTypes = VerifyTokenTypes.EMAIL_CHANGE;
}
