



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

import LogoHeader from '@/components/LogoHeader/LogoHeader.vue';
import LandingCard from '@/layouts/LandingCard/LandingCard.vue';
import LandingLayout from '@/layouts/LandingLayout/LandingLayout.vue';
import BrandMixin from '../../mixins/BrandMixin';
import { PLATFORMS } from '../../scripts/config';

import regex from '@/scripts/regex';
import {
  sendForgotPassword,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
} from '@/scripts/sendForgotPassword';
import { Locale } from '@paystone/locale';

@Component({
  components: {
    LandingCard,
    LandingLayout,
    LogoHeader,
  },
})
export default class ForgotPassword extends BrandMixin {
  private forgotPasswordLockout: boolean = false;
  private forgotPasswordComplete: boolean = false;

  public $refs: any = {
    email: HTMLElement,
  };

  private validSections: any = {
    email: false,
  };

  private vModel: any = {
    email: '',
  };

  private modalMessage: VueI18n.TranslateResult = this.locale(
    'Modal.genericError',
  );

  private rules: any = {
    email: [
      (value: string) => {
        return regex.email.test(value) ? true : this.locale('emailInvalid');
      },
    ],
  };

  private get valid(): boolean {
    return Object.values(this.validSections).indexOf(false) === -1;
  }

  private locale(value: string) {
    return this.$t(`ForgotPassword.${value}`, { brandName: this.brandName });
  }

  private showModal(modalLocaleKey: string): void {
    this.modalMessage = this.locale(`Modal.${modalLocaleKey}`);
    this.$ZModal.show('forgotPasswordModal');
  }

  private createForgotPasswordRequest(): ForgotPasswordRequest {
    return {
      email: this.vModel.email,
      app: 'portal',
      language: this.$i18n.locale as Locale,
    };
  }

  private showResultNotification(response: ForgotPasswordResponse): void {
    switch (response) {
      case ForgotPasswordResponse.NOT_VERIFIED: {
        this.showModal('accountNotVerified');
        break;
      }
      case ForgotPasswordResponse.NOT_REGISTERED: {
        this.showModal('emailNotRegistered');
        break;
      }
      case ForgotPasswordResponse.OK: {
        this.forgotPasswordComplete = true;
        break;
      }
      default: {
        this.showModal('genericError');
        break;
      }
    }
  }

  private async forgotPassword() {
    if (this.valid) {
      if (this.forgotPasswordLockout) return;
      this.forgotPasswordLockout = true;
      try {
        let platformKey: string;

        if (
          this.$route &&
          this.$route.params &&
          this.$route.params.platformKey
        ) {
          platformKey = PLATFORMS[this.$route.params.platformKey]
            .platformKey as string;
        } else {
          platformKey = PLATFORMS.account.platformKey as string;
        }
        const response = await sendForgotPassword(
          this.createForgotPasswordRequest(),
          platformKey,
        );
        this.showResultNotification(response);
      } catch (e) {
        this.showResultNotification(ForgotPasswordResponse.ERROR);
      } finally {
        this.forgotPasswordLockout = false;
      }
    }
  }
}
