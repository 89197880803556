



















import { Component, Prop, Vue } from 'vue-property-decorator';
import LogoHeader from '@/components/LogoHeader/LogoHeader.vue';
import LandingCard from '@/layouts/LandingCard/LandingCard.vue';
import LandingLayout from '@/layouts/LandingLayout/LandingLayout.vue';
import {
  sendUnsubscribe,
  UnsubscribeResponse,
} from '@/scripts/sendUnsubscribe';
import ValidateTokenMixin from '../../mixins/ValidateTokenMixin';

@Component({
  components: {
    LandingCard,
    LandingLayout,
    LogoHeader,
  },
})
export default class GivepointUnsubscribe extends ValidateTokenMixin {
  private unsubscribeResponse: UnsubscribeResponse | number = -1;

  protected locale(key: string) {
    return this.$t(`GivepointUnsubscribe.${key}`, {
      brandName: this.brandName,
    });
  }

  protected async created() {
    if (this.tokenId) {
      try {
        this.unsubscribeResponse = await sendUnsubscribe(this.tokenId);
      } catch (e) {
        this.showError();
      }
    } else {
      this.showError();
    }
  }

  private get success() {
    return this.unsubscribeResponse === UnsubscribeResponse.OK;
  }

  private get error() {
    return this.unsubscribeResponse === UnsubscribeResponse.ERROR;
  }

  private get body() {
    if (!this.success && !this.error) {
      return this.locale('body');
    } else if (this.success) {
      return this.locale('success');
    }
    return this.locale('error');
  }

  private showError() {
    this.unsubscribeResponse = UnsubscribeResponse.ERROR;
  }
}
