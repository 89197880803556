












import BrandMixin from '../../mixins/BrandMixin';
import { Vue, Prop, Component } from 'vue-property-decorator';
import { PLATFORMS } from '../../scripts/config';
import LocaleChanger from '../LocaleChanger/LocaleChanger.vue';

@Component({
  components: {
    LocaleChanger,
  },
})
export default class LogoHeader extends Vue {
  @Prop({ required: true, default: PLATFORMS.pay.key })
  private platform?: any;

  protected get isFundraising(): boolean {
    return (
      this.platform.toLowerCase() === PLATFORMS.givepoint.key.toLowerCase()
    );
  }
}
