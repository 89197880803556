import { Component, Watch } from 'vue-property-decorator';
import ValidateTokenMixin from './ValidateTokenMixin';

import ValidateTokenService, {
  TokenStatus,
  VerifyTokenTypes,
} from '@/services/ValidateTokenService';

// @ts-ignore
@Component
abstract class AbstractVerifyTokenMixin extends ValidateTokenMixin {
  protected abstract verifyTokenType: VerifyTokenTypes;
  protected tokenVerified: boolean = false;
  protected resendOnExpired: boolean = false;

  @Watch('checkTokenResponse')
  protected async watchCheckTokenResponse() {
    if (
      this.checkTokenResponse &&
      this.checkTokenResponse.status === TokenStatus.OK
    ) {
      try {
        await this.verifyToken();
      } catch (error) {
        this.showModal('tokenGenericErrorModal');
      }
    }
  }

  protected async checkToken() {
    if (this.tokenId) {
      try {
        this.checkTokenResponse = await ValidateTokenService.getToken(
          this.tokenId,
        );
        if (this.checkTokenResponse.platform) {
          this.platform = this.checkTokenResponse.platform;
        }

        switch (this.checkTokenResponse.status) {
          case TokenStatus.ALREADY_ACCEPTED: {
            this.showModal('tokenAlreadyAccepted');
            break;
          }
          case TokenStatus.EXPIRED: {
            await this.verifyToken();
            break;
          }
          case TokenStatus.NOT_FOUND: {
            this.showModal('tokenRequestNotFoundModal');
            break;
          }
          case TokenStatus.ERROR: {
            this.showModal('tokenGenericErrorModal');
            break;
          }
        }
      } catch (error) {
        this.showModal('tokenGenericErrorModal');
      }
    } else {
      this.showModal('tokenGenericErrorModal');
    }
  }

  private async verifyToken() {
    if (this.tokenId) {
      try {
        const verifyResponse: ValidateTokenServiceResponse = await ValidateTokenService.verifyToken(
          this.tokenId,
          this.verifyTokenType,
        );
        const { status } = verifyResponse;
        if (status === TokenStatus.OK) {
          this.tokenVerified = true;
        } else if (status === TokenStatus.EXPIRED) {
          if (this.resendOnExpired) {
            this.resendVerificationToken();
          } else {
            this.showModal('tokenExpiredModal');
          }
        } else {
          this.showModal('tokenGenericErrorModal');
        }
      } catch (e) {
        this.showModal('tokenGenericErrorModal');
      }
    }
  }

  private async resendVerificationToken() {
    if (
      this.tokenId &&
      this.checkTokenResponse &&
      this.checkTokenResponse.user &&
      this.checkTokenResponse.type
    ) {
      try {
        const verifyResponse: ValidateTokenServiceResponse = await ValidateTokenService.resendUserVerificationToken(
          this.checkTokenResponse.user,
          this.checkTokenResponse.type,
        );
        const { status } = verifyResponse;
        if (status === TokenStatus.OK) {
          this.showModal('tokenReissueExpiredModal');
        } else {
          this.showModal('tokenGenericErrorModal');
        }
      } catch (e) {
        this.showModal('tokenGenericErrorModal');
      }
    }
  }

  protected created() {
    if (!this.tokenId) {
      this.$nextTick(() => {
        this.showModal('tokenGenericErrorModal');
      });
    } else {
      this.checkToken();
    }
  }
}

@Component
// @ts-ignore
export default class VerifyTokenMixin extends AbstractVerifyTokenMixin {}
