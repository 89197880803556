





























































































































































import { Component, Mixins, Prop, Watch, Vue } from 'vue-property-decorator';
import LogoHeader from '@/components/LogoHeader/LogoHeader.vue';
import LandingCard from '@/layouts/LandingCard/LandingCard.vue';
import LandingLayout from '@/layouts/LandingLayout/LandingLayout.vue';
import ValidateTokenMixin from '../../mixins/ValidateTokenMixin';
import BrandMixin from '../../mixins/BrandMixin';
import { get } from 'lodash';
import {
  createUserAccount,
  CreateUserAccountResponse,
} from '@/scripts/createUserAccount';
import { Address as AddressModel, IAddress } from '@zomaroninc/libs-shared';
import { IAddressOptions } from '@zomaroninc/vue-components/src/Zinc/ZAddress/AddressMixin';
import regex from '@/scripts/regex';
import { BRAND_NAMES, PLATFORMS, TOS_URL } from '../../scripts/config';
import { ROUTES } from '../../routes/routes';
import { Locale as LocaleEnum } from '@paystone/locale';

enum SuccessState {
  INCOMPLETE = 'INCOMPLETE',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

interface ISetupAccountVModel {
  email: string;
  firstName: string;
  lastName: string;
  address: IAddress;
  password: string;
  tosAgreement: boolean;
  confirmPassword: string;
  preferredLanguage: string;
}

export interface IInputFields {
  emailAddress?: boolean;
  password?: boolean;
  confirmPassword?: boolean;
  firstName?: boolean;
  lastName?: boolean;
  address?: boolean;
}

@Component({
  components: {
    LandingCard,
    LandingLayout,
    LogoHeader,
  },
})
export default class SetupAccount extends ValidateTokenMixin {
  @Prop() private preset?: ISetupAccountVModel;

  private validSections: IInputFields = {};

  private successState: SuccessState = SuccessState.INCOMPLETE;

  private get isVerified() {
    return this.successState === SuccessState.VERIFIED;
  }

  private get englishLocale(): string {
    return LocaleEnum.enCA;
  }

  private get frenchLocale(): string {
    return LocaleEnum.frCA;
  }

  private get language() {
    return this.$i18n.locale;
  }

  private get requiredFields(): IInputFields {
    let requiredFields: IInputFields = {
      emailAddress: true,
      firstName: true,
      lastName: true,
      password: true,
      confirmPassword: true,
      address: false,
    };

    if (
      get(this, '$route.params.platformKey', '') === PLATFORMS.givepoint.key
    ) {
      requiredFields = {
        emailAddress: true,
        firstName: true,
        lastName: true,
        password: true,
        confirmPassword: true,
        address: true,
      };
    }

    return requiredFields;
  }

  @Watch('requiredFields', { deep: true }) private watchRequiredFields() {
    this.initiateValidation();
  }

  private initiateValidation() {
    const validSections: IInputFields = {};
    Object.keys(this.requiredFields).forEach((key: string) => {
      if (this.requiredFields[key as keyof IInputFields]) {
        validSections[key as keyof IInputFields] = false;
      }
    });
    this.validSections = validSections;
  }

  private get isUnverified() {
    return this.successState === SuccessState.UNVERIFIED;
  }

  public $refs: any = {
    email: HTMLElement,
    firstName: HTMLElement,
    lastName: HTMLElement,
    address: HTMLElement,
    password: HTMLElement,
    tosAgreement: HTMLElement,
    confirmPassword: HTMLElement,
    preferredLanguage: HTMLElement,
  };

  private defaultRequired: IAddressOptions = {
    line1: true,
    line2: false,
    city: true,
    country: true,
    province: true,
    postalCode: true,
  };

  private createAccountLockout: boolean = false;
  private passwordType: string = 'password';
  private selectedPlatform: { [key: string]: {} } = { pay: {} };

  private vModel: ISetupAccountVModel = {
    firstName: '',
    lastName: '',
    email: '',
    address: {},
    confirmPassword: '',
    password: '',
    tosAgreement: false,
    preferredLanguage: '',
  };

  private rules: any = {
    confirmPassword: [
      (value: string) => {
        return this.vModel.password === this.vModel.confirmPassword
          ? true
          : this.locale('passwordConfirmMismatch');
      },
    ],
    emailAddress: [
      (value: string) => {
        return regex.email.test(value) ? true : this.locale('emailInvalid');
      },
    ],
    exists: [
      (value: string) => {
        return value ? true : this.locale('fieldRequired');
      },
    ],
  };

  private get tokenEmail() {
    if (this.tokenId && get(this, 'checkTokenResponse.user.email', false)) {
      this.vModel.email = get(this, 'checkTokenResponse.user.email');
      return true;
    }
    return false;
  }

  private get togglePassword() {
    return this.passwordType;
  }

  private get tosUrl(): string {
    if (
      get(this, '$route.params.platformKey', '') === PLATFORMS.givepoint.key
    ) {
      return `${TOS_URL}#fundraisingterms`;
    }
    return `${TOS_URL}#payterms`;
  }

  private get valid(): boolean {
    const validatedFields = Object.keys(this.requiredFields);
    return (
      Object.values(this.validSections).indexOf(false) === -1 &&
      this.vModel.tosAgreement && this.vModel.preferredLanguage.length > 0
    );
  }

  protected locale(value: string) {
    return this.$t(`SetupAccount.${value}`, { brandName: this.brandName });
  }

  protected goToLogin() {
    let platformUrl: string = '';
    if (this.platform && this.platform.url) {
      platformUrl = this.platform.url;
    } else if (
      get(this, '$route.params.platformKey', '') === PLATFORMS.givepoint.key
    ) {
      platformUrl = PLATFORMS.givepoint.url as string;
    } else {
      platformUrl = PLATFORMS.hub.url as string;
    }

    if (window?.location?.search) {
      platformUrl += window.location.search;
    }

    window.location.href = platformUrl;
  }

  private get userObject(): UserAccount {
    const accountObject: UserAccount = {
      user: {
        email: this.vModel.email,
        password: this.vModel.password,
        platform: this.selectedPlatform,
        preferredLanguage: this.vModel.preferredLanguage,
      },
    };

    if (this.requiredFields.firstName) {
      accountObject.user.firstName = this.vModel.firstName;
    }

    if (this.requiredFields.lastName) {
      accountObject.user.lastName = this.vModel.lastName;
    }

    if (this.requiredFields.address) {
      accountObject.user.address = {
        line1: this.vModel.address.line1 || '',
        line2: this.vModel.address.line2 || '',
        city: this.vModel.address.city || '',
        country: this.vModel.address.country || '',
        postal: this.vModel.address.postalCode || '',
        province: this.vModel.address.province || '',
      };
    }

    return accountObject;
  }

  private async createAccount() {
    this.createAccountLockout = true;
    const userAccount = this.userObject;
    if (this.valid && this.tokenId && userAccount) {
      try {
        const response = await this.createUser(userAccount, this.vModel.preferredLanguage as LocaleEnum, this.tokenId);
        this.readyToShowModal(response, true);
      } catch (e) {
        this.showModal('tokenGenericErrorModal');
      }
    } else if (
      this.valid &&
      !this.tokenId &&
      userAccount &&
      this.selectedPlatform.givepoint !== undefined
    ) {
      const response = await this.createUser(userAccount, this.vModel.preferredLanguage as LocaleEnum);
      this.readyToShowModal(response, false);
    }
    this.createAccountLockout = false;
  }

  private readyToShowModal(
    response: CreateUserAccountResponse,
    verified: boolean = false,
  ) {
    if (response === CreateUserAccountResponse.EMAIL_IN_USE) {
      this.showModal('emailAlreadyInUse');
    } else if (response === CreateUserAccountResponse.ERROR) {
      this.showModal('tokenGenericErrorModal');
    } else {
      if (verified) {
        this.successState = SuccessState.VERIFIED;
      } else {
        this.successState = SuccessState.UNVERIFIED;
      }
    }
  }

  private async createUser(user: UserAccount, language: LocaleEnum, tokenId?: string) {
    let response;
    if (tokenId) {
      response = await createUserAccount({ user, language, tokenId });
    } else {
      response = await createUserAccount({ user, language });
    }
    return response;
  }

  protected created() {
    if (
      get(this, '$route.params.platformKey', '') === PLATFORMS.givepoint.key
    ) {
      this.selectedPlatform = { givepoint: {} };
    }
    if (this.tokenId) {
      this.checkToken();
    }
    if (this.preset) {
      this.vModel = this.preset;
    }

    this.initiateValidation();
  }
}
