import Vue from 'vue';
import VueI18n from 'vue-i18n';
import options from '../locales';

Vue.use(VueI18n);

export default new VueI18n({
  locale: options.locale,
  fallbackLocale: options.fallbackLocale,
  messages: options.messages as any,
});
