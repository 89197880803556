import Vue from 'vue';
import { Route } from 'vue-router';
const trackingMiddleware = (vue: Vue) => (
  to: Route,
  from: Route,
  next: () => void,
) => {
  if (to.name && to.name !== from.name) {
    vue.$gtm.trackView(to.name, to.path);
  }
  next();
};

export default trackingMiddleware;
