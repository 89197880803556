

























import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class VerifyingLayout extends Vue {
  @Prop({ required: true, default: false }) private verified!: boolean;
}
