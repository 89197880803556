import { httpClientFactory } from './httpClientFactory';
import { Locale } from '@paystone/locale';

const resetPassword = async (token: string, password: string, language: Locale) => {
  var data = { password: password, language: language };
  var route = `verification/${token}/password`;
  return httpClientFactory().putReq(route, data);
};

export { resetPassword };
