import { GTMOptions } from '@zomaroninc/vue-npm-plugin-tracking/dist/types';

// Use environment variables to configure GTM
const GTM_CONFIG: GTMOptions = {
  accountId: process.env.VUE_APP_TRACKING_ACCOUNT_ID || '',
  auth: process.env.VUE_APP_TRACKING_AUTH || '',
  enable: process.env.VUE_APP_TRACKING_ENABLE === 'true',
  preview: process.env.VUE_APP_TRACKING_PREVIEW || '',
  cookies: process.env.VUE_APP_TRACKING_COOKIES || '',
};

const APIS = {
  account: process.env.VUE_APP_ACCOUNT_API_URL,
};

const ENVIRONMENT = process.env.VUE_APP_TARGET || 'development';

const PLATFORMS: PlatformList = {
  account: {
    name: 'Account',
    key: 'account',
    platformKey: process.env.VUE_APP_ACCOUNT_PLATFORM_KEY,
  },
  givepoint: {
    name: 'Givepoint',
    key: 'givepoint',
    platformKey: process.env.VUE_APP_GIVEPOINT_PLATFORM_KEY,
    url: process.env.VUE_APP_GIVEPOINT_URL,
    hasFeedback: true,
  },
  givepointHub: {
    name: 'Givepoint Hub',
    key: 'givepoint',
    platformKey: process.env.VUE_APP_GIVEPOINT_HUB_PLATFORM_KEY,
    url: process.env.VUE_APP_HUB_URL,
  },
  pay: {
    name: 'Pay',
    key: 'pay',
    platformKey: process.env.VUE_APP_HUB_PLATFORM_KEY,
    url: process.env.VUE_APP_HUB_URL,
  },
  hub: {
    name: 'Hub',
    key: 'hub',
    platformKey: process.env.VUE_APP_HUB_PLATFORM_KEY,
    url: process.env.VUE_APP_HUB_URL,
  },
  onboarding: {
    name: 'Onboarding',
    key: 'onboarding',
    platformKey: process.env.VUE_APP_ONBOARDING_PLATFORM_KEY,
    url: process.env.VUE_APP_ONBOARDING_URL,
  },
};

const BRAND_NAMES = {
  fundraising: 'Givepoint',
  paystone: 'Paystone',
};

const HOMEPAGE_URL = process.env.VUE_APP_HOMEPAGE_URL;
const TOS_URL = `${HOMEPAGE_URL}/legal`;

export {
  APIS,
  ENVIRONMENT,
  PLATFORMS,
  BRAND_NAMES,
  HOMEPAGE_URL,
  TOS_URL,
  GTM_CONFIG,
};
