import Vue from 'vue';
import App from './App.vue';

import { Zinc } from '@zomaroninc/vue-components';
import '@zomaroninc/libs-frontend/dist/src/ziStyles/main.scss';
import '@zomaroninc/vue-components/dist/VueComponents.css';
import FeatureFlagPlugin from '@/plugins/featureFlags/';
import featureFlagOptions from '@/plugins/featureFlags/defaultValues';
import './plugins/gtm';
import i18n from './plugins/localization';

import router from './routes';
import trackingMiddleware from './routes/trackingMiddleware';

Vue.config.productionTip = false;

Vue.use(Zinc);
Vue.use(FeatureFlagPlugin, featureFlagOptions);

const vue = new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

vue.$router.beforeEach(trackingMiddleware(vue));
