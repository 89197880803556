import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';

import EmailChanged from '@/pages/EmailChanged/EmailChanged.vue';
import ForgotPassword from '@/pages/ForgotPassword/ForgotPassword.vue';
import GivepointUnsubscribe from '@/pages/GivepointUnsubscribe/GivepointUnsubscribe.vue';
import ResetPassword from '@/pages/ResetPassword/ResetPassword.vue';
import Scaffold from '@/pages/PageScaffold.vue';
import SetupAccount from '@/pages/SetupAccount/SetupAccount.vue';
import VerifyUser from '@/pages/VerifyUser/VerifyUser.vue';
import { PLATFORMS, HOMEPAGE_URL } from '@/scripts/config';
import { ROUTES } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/resetPassword',
      components: {
        app: Scaffold,
      },
      children: [
        {
          path: ':tokenId',
          name: ROUTES.resetPassword,
          components: {
            scaffold: ResetPassword,
          },
          props: {
            default: true,
            scaffold: true,
          },
        },
        {
          path: '/',
          components: {
            scaffold: ResetPassword,
          },
        },
      ],
    },
    {
      path: '/emailChanged',
      components: {
        app: Scaffold,
      },
      children: [
        {
          path: ':tokenId',
          name: ROUTES.emailChanged,
          components: {
            scaffold: EmailChanged,
          },
          props: {
            default: true,
            scaffold: true,
          },
        },
        {
          path: '/',
          components: {
            scaffold: EmailChanged,
          },
        },
      ],
    },
    {
      path: '/setupAccount',
      components: {
        app: Scaffold,
      },
      children: [
        {
          path: ':platformKey/:tokenId',
          name: ROUTES.setupAccount,
          beforeEnter: (to, from, next) => {
            if (!validatePlatform(to)) return next('/');
            return next();
          },
          components: {
            scaffold: SetupAccount,
          },
          props: {
            defaults: true,
            scaffold: true,
          },
        },
        {
          path: ':platformKey',
          name: ROUTES.setupAccountNoToken,
          // Currently only givepoint supports creating an account without a token
          beforeEnter: (to, from, next) => {
            if (to.params.platformKey.toLowerCase() !== PLATFORMS.givepoint.key) return next('/');
            return next();
          },
          components: {
            scaffold: SetupAccount,
          },
          props: {
            default: true,
            scaffold: true,
          },
        },
        {
          // TEMPORARY WORKAROUND TO ALLOW MALFORMED GIVEPOINT URLS TO BE PROCESSED
          path: ':platformKey/:tokenId/:junk(.*)',
          name: ROUTES.setupAccount,
          beforeEnter: (to, from, next) => {
            if (!validatePlatform(to)) return next('/');
            return next();
          },
          components: {
            scaffold: SetupAccount,
          },
          props: {
            defaults: true,
            scaffold: true,
          },
        },
        {
          path: '/',
          beforeEnter() {
            window.location.href = HOMEPAGE_URL as string;
          },
        },
      ],
    },
    {
      path: '/verifyUser',
      components: {
        app: Scaffold,
      },
      children: [
        {
          path: ':tokenId',
          name: ROUTES.verifyUser,
          components: {
            scaffold: VerifyUser,
          },
          props: {
            default: true,
            scaffold: true,
          },
        },
        {
          path: '/',
          components: {
            scaffold: VerifyUser,
          },
        },
      ],
    },
    {
      path: '/forgotPassword',
      components: {
        app: Scaffold,
      },
      children: [
        {
          path: ':platformKey',
          name: ROUTES.forgotPassword,
          components: {
            scaffold: ForgotPassword,
          },
          beforeEnter: (to, from, next) => {
            if (!validatePlatform(to)) return next('/');
            return next();
          },
          props: {
            default: true,
            scaffold: true,
          },
        },
        {
          path: '/',
          components: {
            scaffold: ForgotPassword,
          },
        },
      ],
    },
    {
      path: '/unsubscribeGivepoint',
      components: {
        app: Scaffold,
      },
      children: [
        {
          path: ':tokenId',
          components: {
            scaffold: GivepointUnsubscribe,
          },
          props: {
            default: true,
            scaffold: true,
          },
        },
        {
          path: '/',
          beforeEnter() {
            window.location.href = HOMEPAGE_URL as string;
          },
        },
      ],
    },
    {
      path: '*',
      beforeEnter() {
        window.location.href = HOMEPAGE_URL as string;
      },
    },
  ],
});

const validatePlatform = (to: Route): Boolean => {
  const platform = to.params.platformKey.toLowerCase();
  let found: boolean = false;
  Object.keys(PLATFORMS).forEach((value: string) => {
    if (PLATFORMS[value].key.toLowerCase() === platform) {
      found = true;
    }
  });
  return found;
};

export default router;
