export default {
  city: /^\w{1,}[^]{0,300}$/,
  // tslint:disable-next-line
  currency: /^\$?([1-9]{1}[0-9]{0,2}(,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/,
  description: /^\w{1,}[^]{0,2000}$/,
  orgDescription: /^\w{1,}[^]{0,300}$/,
  // tslint:disable-next-line
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  firstName: /^\w{1,}[^]{0,200}$/,
  fullName: /^\w{1,}[^]{0,200}$/,
  lastName: /^\w{1,}[^]{0,400}$/,
  line1: /^\w{1,}[^]{0,1000}$/,
  name: /^\w{1,}[^]{0,200}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*|?&:/\\.;,()<>`'"~#^\-_=+{}:[\]])([A-Za-z\d@$!%*|?&:/\\.;,()<>`'"~#^\-_=+{}:[\]]{8,})/,
  phone: /^(\d-?){8,30}$/,
  postal: /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/,
  province: /^(AB|BC|MB|NB|NL|NS|ON|PE|QC|SK|NT|NU|YT)$/,
  title: /^\w{1,}[^]{0,200}$/,
  transitNumber: /^\d{5}$/,
  institutionNumber: /^\d{3,4}$/,
  accountNumber: /^\d{4,12}$/,
  simProvider: /^(rogers|telus|bell)$/i,
  website: /^((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
};
