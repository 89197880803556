const LD_CLIENT_KEY_ACCOUNT_MANAGER =
  process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_ACCOUNT_MANAGER || '';
const LD_USER_KEY = process.env.VUE_APP_LAUNCH_DARKLY_USER || '';

export default {
  'account-manager': {
    userKey: LD_USER_KEY,
    clientKey: LD_CLIENT_KEY_ACCOUNT_MANAGER,
    flags: {
      'toggle-localization': false,
    },
  },
};
