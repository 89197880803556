import { HttpClient } from '@zomaroninc/libs-frontend';
import { APIS, PLATFORMS } from '@/scripts/config';

const clients: any = [];

export const httpClientFactory = (
  platformKey: string = PLATFORMS.account.platformKey as string,
): HttpClient => {
  // Create a maximum of one client per platform
  if (!clients[platformKey]) {
    clients[platformKey] = new HttpClient({
      api: APIS.account as string,
      platformKey: platformKey || PLATFORMS.account.platformKey,
      config: {
        timeout: 5000,
      },
      reauthentication: {
        errorCodes: [],
        reauthenticate: async function(this: HttpClient) {
          throw new Error('No authentication specified');
        },
        onError: async () => {},
      },
      getAuthHeader: (): string => {
        return '';
      },
    });
  }

  return clients[platformKey];
};
