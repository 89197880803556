export enum ROUTES {
  setupPayAccount = 'setupPayAccount',
  setupGivepointAccountNoToken = 'setupGivepointAccountNoToken',
  setupGivepointAccount = 'setupGivepointAccount',
  setupAccount = 'setupAccount',
  setupAccountNoToken = 'setupAccountNoToken',
  forgotPassword = 'forgotPassword',
  resetPassword = 'resetPassword',
  emailChanged = 'emailChanged',
  verifyUser = 'verifyUser',
}
