import _get from 'lodash/get';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BRAND_NAMES, PLATFORMS, HOMEPAGE_URL } from '@/scripts/config';

@Component
export default class BrandMixin extends Vue {
  protected platform?: PlatformToken | null = null;

  protected get brandName() {
    if (
      _get(this, '$route.params.platformKey', '') === PLATFORMS.givepoint.key
    ) {
      return BRAND_NAMES.fundraising;
    } else if (_get(this.platform, 'key', false) === PLATFORMS.givepoint.key) {
      return BRAND_NAMES.fundraising;
    }
    return BRAND_NAMES.paystone;
  }

  protected get logoName() {
    if (
      _get(this, '$route.params.platformKey', '') === PLATFORMS.givepoint.key
    ) {
      return PLATFORMS.givepoint.key;
    } else if (_get(this.platform, 'key', false) === PLATFORMS.givepoint.key) {
      return PLATFORMS.givepoint.key;
    }
    return '';
  }

  protected goToLogin() {
    const urlKey = _get(this, '$route.params.platformKey', '');
    let platformUrl: string = '';
    if (urlKey in PLATFORMS) {
      platformUrl = PLATFORMS[urlKey].url as string;
    } else if (this.platform && this.platform.url) {
      platformUrl = this.platform.url;
    } else {
      platformUrl = HOMEPAGE_URL as string;
    }
    if (window?.location?.search) {
      platformUrl += window.location.search;
    }
    window.location.href = platformUrl;
  }

  protected get isFundraising() {
    return this.brandName === BRAND_NAMES.fundraising;
  }
}
