import { httpClientFactory } from '../scripts/httpClientFactory';
import { PLATFORMS } from '../scripts/config';

export enum TokenStatus {
  ALREADY_ACCEPTED,
  EXPIRED,
  NOT_FOUND,
  ERROR,
  OK,
}

/**
 * Error responses from verification routes
 *
 * NOTE checkToken() DOES NOT return error codes for ALREADY_ACCEPTED or EXPIRED tokens.
 */
export enum ErrorCodes {
  REQUEST_NOT_FOUND = 40001,
  ALREADY_ACCEPTED = 40015,
  EXPIRED = 40020,
}

export enum VerifyTokenTypes {
  VERIFY_USER = 'register',
  EMAIL_CHANGE = 'email',
}

export default class ValidateTokenService {
  static async getToken(
    tokenId: string,
  ): Promise<ValidateTokenServiceResponse> {
    try {
      const verificationResponse: VerificationResponse = await httpClientFactory().getReq(
        'verification/' + tokenId + '?populate=user',
      );
      const user: User = verificationResponse.user;
      const type: string = verificationResponse.type;
      let platform: PlatformToken | undefined;

      // Find platform
      for (const key of Object.keys(PLATFORMS)) {
        if (PLATFORMS[key].platformKey === verificationResponse.platform) {
          platform = PLATFORMS[key];
          break;
        }
      }

      let response = {
        user: user,
        platform: platform,
        type: type,
      };

      if (verificationResponse.expires < Date.now()) {
        return {
          status: TokenStatus.EXPIRED,
          ...response,
        };
      } else if (verificationResponse.accepted > 0) {
        return {
          status: TokenStatus.ALREADY_ACCEPTED,
          ...response,
        };
      } else {
        return {
          status: TokenStatus.OK,
          ...response,
        };
      }
    } catch (e) {
      const error = { ...e.data.error };
      if (error.code === ErrorCodes.REQUEST_NOT_FOUND) {
        return {
          status: TokenStatus.NOT_FOUND,
        };
      } else {
        return {
          status: TokenStatus.ERROR,
        };
      }
    }
  }

  static async verifyToken(
    tokenId: string,
    route: string,
  ): Promise<ValidateTokenServiceResponse> {
    const endpoint = `verification/${tokenId}/${route}`;
    const data = { token: tokenId };
    try {
      await httpClientFactory().putReq(endpoint, data);
      return {
        status: TokenStatus.OK,
      };
    } catch (e) {
      const error = { ...e.data.error };
      if (error.code === ErrorCodes.REQUEST_NOT_FOUND) {
        return {
          status: TokenStatus.NOT_FOUND,
        };
      } else if (error.code === ErrorCodes.EXPIRED) {
        return {
          status: TokenStatus.EXPIRED,
        };
      } else if (error.code === ErrorCodes.ALREADY_ACCEPTED) {
        return {
          status: TokenStatus.ALREADY_ACCEPTED,
        };
      } else {
        return {
          status: TokenStatus.ERROR,
        };
      }
    }
  }

  static async verifyUserToken(
    tokenId: string,
  ): Promise<ValidateTokenServiceResponse> {
    return this.verifyToken(tokenId, 'register');
  }

  static async resendUserVerificationToken(
    user: User,
    type: string,
  ): Promise<ValidateTokenServiceResponse> {
    var route = `verification/resend/${user.email}/${type}`;
    try {
      await httpClientFactory().postReq(route, {});
      return {
        status: TokenStatus.OK,
      };
    } catch (e) {
      return {
        status: TokenStatus.ERROR,
      };
    }
  }
}
