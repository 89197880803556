






import { Vue, Prop, Component } from 'vue-property-decorator';
import { Locale, isLocale } from '@paystone/locale';

@Component
export default class LocaleChanger extends Vue {
  get nextLocale() {
    return (this.$i18n.locale === Locale.frCA) ? Locale.enCA : Locale.frCA;
  }

  get localizable() {
    return this.$featureFlags.projects['account-manager'].flags['toggle-localization'];
  }

  locale(key: string) {
    return this.$t(`Locales.${key}`);
  }

  changeLocale(locale: Locale) {
    localStorage.setItem('locale', locale);
    this.$i18n.locale = locale;
    document.title = this.$t('PageTitle').toString();
  }

  beforeMount() {
    let currentLocale: Locale = Locale.enCA;
    const queryParams = window.location.search.split('&');
    for (let i = 0; i < queryParams.length; i++) {
      const param = queryParams[i].split('=');
      if (param.length > 1 && param[0].indexOf('lang') >= 0 && isLocale(param[1])) {
        currentLocale = param[1] as Locale;
      }
    }
    this.changeLocale(currentLocale);
  }
}
