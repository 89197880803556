import { httpClientFactory } from './httpClientFactory';
import { PLATFORMS } from '@/scripts/config';
import { Locale as LocaleEnum } from '@paystone/locale';

export enum CreateUserAccountResponse {
  EMAIL_IN_USE,
  ERROR,
  OK,
}

export enum ErrorCodes {
  EMAIL_IN_USE = 40017,
}

export type CreateUserAccountPayload = {
  user: UserAccount;
  language: LocaleEnum;
  tokenId?: string;
}

const createUserAccount: (
  payload: CreateUserAccountPayload,
) => Promise<CreateUserAccountResponse> = async (
  payload: CreateUserAccountPayload
) => {
  let client;
  const givepointKey: keyof Platform = PLATFORMS.givepoint
    .key as keyof Platform;
  const userPayload = payload.user.user;
  if (
    userPayload &&
    userPayload.platform &&
    userPayload.platform[givepointKey]
  ) {
    client = httpClientFactory(PLATFORMS.givepoint.platformKey);
  } else {
    client = httpClientFactory(PLATFORMS.hub.platformKey);
  }
  try {
    let requestPayload = {
      ...payload.user,
      language: payload.language,
    };
    const path = payload.tokenId ? 'account/token/' + payload.tokenId : '/account';
    await client.postReq(path, requestPayload);
    return CreateUserAccountResponse.OK;
  } catch (e) {
    if (
      e &&
      e.data &&
      e.data.error &&
      e.data.error.code === ErrorCodes.EMAIL_IN_USE
    ) {
      return CreateUserAccountResponse.EMAIL_IN_USE;
    } else {
      return CreateUserAccountResponse.ERROR;
    }
  }
};

export { createUserAccount };
