// tslint:disable:max-line-length
import Locale from './locale';
import { Locale as LocaleEnum } from '@paystone/locale';

const fr: Locale = {
  PageTitle: `Votre compte Paystone`,
  ResetPassword: {
    navButtonLabel: `Aller à la page d’accueil`,
    title: `Réinitialisez votre mot de passe`,
    body: `Veuillez saisir votre nouveau mot de passe ci-dessous. Vous serez ensuite redirigé vers la page de connexion.`,
    passwordLabel: `Nouveau mot de passe`,
    passwordConfirmLabel: `Confirmez votre nouveau mot de passe`,
    passwordConfirmMismatch: `Les mots de passe ne concordent pas`,
    resetButtonLabel: `Réinitialiser le mot de passe`,
    successTitle: `Votre mot de passe a été changé`,
    successBody:
      `Vous pouvez maintenant utiliser votre nouveau mot de passe pour vous connecter à votre compte.`,
    successButtonLabel: `Aller à la page d’accueil`,
    Modal: {
      dismiss: `Fermer`,
      tokenGenericErrorModal: `Votre compte a déjà été validé. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenRequestNotFoundModal: `Une erreur s’est produite, ou le lien est expiré. Veuillez réinitialiser votre mot de passe à nouveau.`,
      tokenSuccessModal: `Votre adresse courriel a été réinitialisée. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenAlreadyAccepted: `Votre adresse courriel a été réinitialisée. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenExpiredModal: `Ce lien est expiré. Vous serez maintenant redirigé vers la page d’accueil, où vous pourrez réinitialiser votre mot de passe.`,
    },
  },
  EmailChanged: {
    verifyingText: `Validation en cours`,
    title: 'Adresse courriel modifiée',
    body: 'Vous pouvez maintenant vous connecter à votre compte.',
    buttonLabel: 'Aller à la page d’accueil',
    Modal: {
      dismiss: `Fermer`,
      tokenGenericErrorModal: `Une erreur s’est produite. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenAlreadyAccepted: `Votre adresse courriel a déjà été validée. Vous serez maintenant redirigé vers la page d’accueil, où vous pourrez vous connecter avec votre nouveau mot de passe.`,
      tokenRequestNotFoundModal: `Une erreur s’est produite. Veuillez réessayer de changer votre adresse courriel.`,
      tokenExpiredModal: `Ce lien est expiré. Vous serez maintenant redirigé vers la page d’accueil, où vous pourrez vous connecter avec votre nouvelle adresse courriel ou la changer au besoin.`,
      tokenSuccessModal: `Votre adresse courriel a été validée. Vous serez maintenant redirigé vers la page d’accueil, où vous pourrez vous connecter avec votre nouvelle adresse courriel.`,
    },
  },
  SetupAccount: {
    title: `Créez un compte`,
    body: `Veuillez remplir ce formulaire pour créer un compte Paystone.`,
    preferredLanguage: 'Langue privilégiée pour les communications',
    english: 'Anglais',
    french: 'Français',
    addressHeader: `Adresse`,
    profileHeader: `Profil`,
    emailLabel: `Adresse courriel`,
    emailInvalid: `Veuillez indiquer une adresse courriel valide.`,
    fieldRequired: `Ce champ est requis`,
    firstNameLabel: `Prénom`,
    lastNameLabel: `Nom`,
    passwordConfirmLabel: `Confirmez votre nouveau mot de passe`,
    passwordConfirmMismatch: `Les mots de passe ne concordent pas.`,
    passwordLabel: `Mot de passe`,
    setupButtonLabel: `Créer le compte`,
    tosAgreementHeader: `Conditions d’utilisation et politique de confidentialité`,
    tosAgreementLabel: `En cochant cette case, vous acceptez les`,
    tosLinkLabel: `Conditions de service et la Politique de protection des renseignements personnels de Paystone.`,
    verifiedTitle: `Votre compte a été créé.`,
    verifiedTagline: `Merci d’avoir créé un compte {brandName}. Vous pouvez maintenant vous connecter à votre compte.`,
    verifiedButtonLabel: `Aller à la page d’accueil`,
    unverifiedTitle: `Validation requise`,
    unverifiedTagline: `Merci d’avoir créé votre compte Paystone. Vous devriez avoir reçu un courriel de confirmation. Veuillez valider votre compte en suivant le lien dans le courriel.`,
    unverifiedButtonLabel: `Aller à la page d’accueil`,
    Modal: {
      dismiss: `Fermer`,
      tokenGenericErrorModal: `Votre compte a déjà été validé. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenAlreadyAccepted: `Un compte a déjà été créé à partir de ce lien. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenRequestNotFoundModal: `Une erreur s’est produite. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenExpiredModal: `Ce lien est expiré. Un courriel de création de compte vous a été envoyé. Veuillez vérifier votre boîte de réception.`,
      tokenSuccessModal: `Votre compte a été créé. Vous serez maintenant redirigé vers la page d’accueil.`,
      emailAlreadyInUse: `Cette adresse courriel est déjà associée à un compte.`,
    },
  },
  VerifyUser: {
    verifyingText: 'Validation en cours',
    title: 'Validation réussie',
    body: 'Vous pouvez maintenant vous connecter à votre compte.',
    buttonLabel: 'Aller à la page d’accueil',
    Modal: {
      dismiss: `Fermer`,
      tokenGenericErrorModal: `Votre compte a déjà été validé. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenAlreadyAccepted: `Votre compte a déjà été validé. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenRequestNotFoundModal: `Une erreur s’est produite. Veuillez vous assurer d’avoir bien écrit votre adresse courriel, ou créez un nouveau compte.`,
      tokenExpiredModal: `Ce lien est expiré. Vous serez maintenant redirigé vers la page d’accueil.`,
      tokenSuccessModal: `Votre compte a déjà été validé. Vous serez maintenant redirigé vers la page d’accueil, où vous pourrez vous connecter à votre compte.`,
      tokenReissueExpiredModal: `Ce lien est expiré. Un nouveau courriel de création de compte vous a été envoyé. Veuillez vérifier votre boîte de réception.`,
    },
  },
  ForgotPassword: {
    title: `Vous avez oublié votre mot de passe?`,
    body: `Veuillez nous indiquer votre adresse courriel ci-dessous et nous vous enverrons les instructions pour réinitialiser votre mot de passe.`,
    buttonLabel: `Envoyer`,
    emailInvalid: `Veuillez indiquer une adresse courriel valide.`,
    emailLabel: `Adresse courriel`,
    successTitle: 'Veuillez vérifier votre boîte de réception.',
    successBody: 'Nous vous avons envoyé des instructions par courriel pour réinitialiser votre mot de passe.',
    successButtonLabel: 'Aller à la page d’accueil',
    Modal: {
      accountNotVerified: `Votre compte a été créé mais n’a pas été validé. Veuillez vérifier votre boîte de réception pour le courriel de validation.`,
      dismiss: `Fermer`,
      emailNotRegistered: `Aucun compte n’est associé à cette adresse courriel.`,
      emailSent: `Des instructions pour réinitialiser votre mot de passe vous ont été envoyées par courriel. Veuillez vérifier votre boîte de réception.`,
      genericError: `Un erreur s’est produite. Veuillez réessayer un peu plus tard.`,
    },
  },
  GivepointUnsubscribe: {
    title: `{brandName} Communications`,
    body: `Please wait, while we process your unsubscription request.`,
    success: `You are now unsubscribed from {brandName} communications.`,
    error: `Failed to unsubscribe, please try again later.`,
  },
  Locales: {
    [LocaleEnum.enCA]: 'English',
    [LocaleEnum.frCA]: 'Français',
  },
};

export default fr;
