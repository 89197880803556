









































































import { Locale } from '@paystone/locale';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LogoHeader from '@/components/LogoHeader/LogoHeader.vue';
import LandingCard from '@/layouts/LandingCard/LandingCard.vue';
import LandingLayout from '@/layouts/LandingLayout/LandingLayout.vue';
import ValidateTokenMixin from '../../mixins/ValidateTokenMixin';

import { resetPassword } from '@/scripts/resetPassword';

@Component({
  components: {
    LandingCard,
    LandingLayout,
    LogoHeader,
  },
})
export default class ResetPassword extends ValidateTokenMixin {
  public $refs: any = {
    password: HTMLElement,
    confirmPassword: HTMLElement,
  };

  private validSections: any = {
    password: false,
    confirmPassword: false,
  };

  private passwordResetComplete: boolean = false;

  private resetPasswordLockout: boolean = false;
  private passwordType: string = 'password';

  private vModel: any = {
    confirmPassword: '',
    password: '',
  };

  private rules: any = {
    confirmPassword: [
      (value: string) => {
        return this.vModel.password === this.vModel.confirmPassword
          ? true
          : this.locale('passwordConfirmMismatch');
      },
    ],
  };

  private created() {
    if (!this.tokenId) {
      this.$nextTick(() => {
        this.showModal('tokenGenericErrorModal');
      });
    } else {
      this.checkToken();
    }
  }

  private get togglePassword() {
    return this.passwordType;
  }

  private get valid(): boolean {
    return Object.values(this.validSections).indexOf(false) === -1;
  }

  private get language() {
    return this.$i18n.locale;
  }

  protected locale(value: string) {
    return this.$t(`ResetPassword.${value}`, { brandName: this.brandName });
  }

  private async resetPassword() {
    if (this.resetPasswordLockout) return;
    this.resetPasswordLockout = true;

    if (this.valid && this.tokenId) {
      try {
        const response = await resetPassword(
          this.tokenId,
          this.vModel.password,
          this.$i18n.locale as Locale,
        );
        this.passwordResetComplete = true;
      } catch (e) {
        this.showModal('tokenGenericErrorModal');
      }
    }
    this.resetPasswordLockout = false;
  }
}
