import Locale from '@/locales/locale';
import { Locale as LocaleEnum } from '@paystone/locale';
import enCAMessages from './enCA';
import frCAMessages from './frCA';

export const LOCALE = LocaleEnum.enCA;
export const FALLBACK_LOCALE = LocaleEnum.enCA;

interface IMessages {
  [key: string]: Locale;
}

interface ILocalePlugin {
  locale: LocaleEnum.enCA;
  fallbackLocale: LocaleEnum;
  messages: IMessages;
}

export const localeMessages: IMessages = {
  [LocaleEnum.enCA]: enCAMessages,
  [LocaleEnum.frCA]: frCAMessages,
};

const LocalePlugin: ILocalePlugin = {
  locale: LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages: localeMessages,
};

export default LocalePlugin;
