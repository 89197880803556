import { httpClientFactory } from './httpClientFactory';
import { PLATFORMS } from './config';

export enum UnsubscribeResponse {
  ERROR,
  OK,
}

const sendUnsubscribe: (
  tokenId: string,
) => Promise<UnsubscribeResponse> = async (tokenId: string) => {
  try {
    await httpClientFactory(PLATFORMS.givepoint.platformKey).getReq(
      `/account/unsubscribe/${tokenId}`,
    );
    return UnsubscribeResponse.OK;
  } catch (error) {
    return UnsubscribeResponse.ERROR;
  }
};

export { sendUnsubscribe };
