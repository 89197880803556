import { httpClientFactory } from './httpClientFactory';
import { Locale } from '@paystone/locale';

export interface ForgotPasswordRequest {
  email: string;
  app: string;
  language: Locale;
}

export enum ForgotPasswordResponse {
  ERROR,
  OK,
  NOT_REGISTERED,
  NOT_VERIFIED,
}

export enum ForgotPasswordErrorCodes {
  NOT_REGISTERED = 40009,
  NOT_VERIFIED = 40025,
}

const sendForgotPassword: (
  request: ForgotPasswordRequest,
  platformKey: string,
) => Promise<ForgotPasswordResponse> = async (
  request: ForgotPasswordRequest,
  platformKey: string,
) => {
  try {
    let client = httpClientFactory(platformKey);
    await client.postReq('account/password/forgot', request);
    return ForgotPasswordResponse.OK;
  } catch (e) {
    if (e && e.data && e.data.error) {
      const error = { ...e.data.error };
      switch (error.code) {
        case ForgotPasswordErrorCodes.NOT_REGISTERED:
          return ForgotPasswordResponse.NOT_REGISTERED;
        case ForgotPasswordErrorCodes.NOT_VERIFIED:
          return ForgotPasswordResponse.NOT_VERIFIED;
      }
    }
    return ForgotPasswordResponse.ERROR;
  }
};

export { sendForgotPassword };
